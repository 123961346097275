import React, {useEffect} from "react";
import nprogress from "nprogress";

import "nprogress/nprogress.css";

// npm install --save nprogress
export default function NProgress(props) {
    useEffect(() => {
        nprogress.configure({minimum: 0.4});
        nprogress.start()
        return () => {
            nprogress.done()
        }
    }, [])

    return <></>
}
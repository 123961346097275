import {artifactId} from "../config-local"


const emptyState = {
    id: "",
    nickname: "",
    //avatar: "",
    avatar_url: "",
    unread: 0   // 未读消息数
}

let initialState = {...emptyState}

const storageKey = artifactId + '-userState'


let user = window.localStorage.getItem(storageKey)

if (user != null) {
    try {
        initialState = {...initialState, ...JSON.parse(user)}
    } catch (e) {
        console.log(e.message)
    }
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'CHANGE_USER':

            let user = {...state, ...action.user}
            window.localStorage.setItem(storageKey, JSON.stringify(user))

            return user

        case 'CHANGE_UNREAD':
            return {...state, unread: action.payload}

        case 'CLEAR_USER':

            window.localStorage.removeItem(storageKey)
            return {...emptyState}
        default:
            return state
    }
}


import React from 'react';
import ReactDOM from "react-dom";

// "@ant-design/cssinjs": "^1.21.1" 官方说 antd 用这个兼容方案，版本要与 antd 匹配 "antd": "5.21.5" 对应的 cssinjs 为1.21.1，在下面链接查看
// https://github.com/ant-design/ant-design/blob/master/package.json

import {legacyLogicalPropertiesTransformer, StyleProvider} from '@ant-design/cssinjs';

import Main from './main'
import common from "./utils/common";

if (common.supportsWherePseudoClass()) {
    ReactDOM.render(
        <Main/>,
        document.getElementById('root')
    );

} else {
    // 针对不支持 :where 选择器的浏览器（例如低于chrome 88 的） 启用兼容方案，不然编辑器页面弹窗没法显示 /run/c++?mode=oj&problem=P1001
    // https://ant-design.antgroup.com/docs/react/compatible-style-cn
    ReactDOM.render(
        <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
            <Main/>
        </StyleProvider>
        ,
        document.getElementById('root')
    );
}


import React, {useEffect, useState} from 'react';
import './oauth.less'
import common from "../../utils/common";
import {Button} from "antd";

function Oauth(props) {

    const {setShow, name, redirect} = props
    const [user, setUser] = useState({})

    useEffect(() => {
        //拉取当前用户的基础信息

        common.loadingStart()
        common.ajax("get", "/api/user/whoami", {}, {
            useToken: true,
            interceptInvalidToken: false,
        }).then((data) => {
            setUser(data)
        }).catch((e) => {

            console.log(e)

            // 没有登录时，显示登录窗口
            setShow("login")

        }).finally(common.loadingStop)

    }, [])


    return (
        <div className={'LoginOauth'}>
            <h1 className={'oauthTitle'}>快捷登录</h1>

            <div className={'userinfo'}>
                <img src={user.avatar_url} alt="头像" width={80} height={80}/>
                <div>{user.nickname}</div>

            </div>

            <div className={'client'}>授权登录：{name}</div>

            <div>
                <Button type={"primary"} block={true} onClick={redirect}>授权登录</Button>
            </div>
        </div>
    );
}

export default Oauth;
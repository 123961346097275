let initialState = {
    selectedKey: "",
    loginPopup: false,
}


export default function (state = initialState, action) {
    switch (action.type) {
        case 'CHANGE_NAV':
            return {...state, selectedKey: action.selectedKey}
        case 'CHANGE_LOGIN_POPUP':
            return {...state, loginPopup: action.loginPopup}

        default:
            return state
    }
}


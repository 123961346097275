// let baseApi = 'http://edu-course-backend.it266.com'
// let baseApi = 'https://www.itnova.top'
// let baseApi = 'http://edu-course-backend.it266.com'
// let baseApi = 'http://localhost:8080/web/index.php'
let baseApi = 'https://www.daimaku.net'

let baseApiLocal = window.localStorage.getItem("baseApi")

if (baseApiLocal) {
    baseApi = baseApiLocal
}


// 与 admin 端保持相同
let artifactId = 'daimaku-v3'

let baseUrlOj = 'https://www.daimaku.net/oj'
let shareWs = 'wss://www.it266.com:8011/'
// let shareWs = 'ws://192.168.31.196:8010/'

export {baseApi, artifactId, baseUrlOj, shareWs}

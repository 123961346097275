let initialState = {
    id: 0,
    name: "",
    logo: "",
    copyright: "",
    icp: "",
    company: "",
    qrcode: "",
    vlog: "",
    intro: "",
    slogan: "",
    background: "",
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'CHANGE_TENANT':
            return {...action.tenant}
        default:
            return state
    }
}


import {createStore, combineReducers} from 'redux'
import user from '../reducers/user'
import nav from '../reducers/nav'
import history from '../reducers/history'
import tenant from '../reducers/tenant'


const reducers = combineReducers({
    user,
    nav,
    history,
    tenant,
})

const store = createStore(reducers);

export default store

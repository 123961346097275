import React from 'react'
import {CloseOutlined} from '@ant-design/icons';
import Passport from "../../pages/passport";
import './style.less'

function PopupLogin(props) {
    const {onClose} = props
    return (
        <>
            <div className="popup-login">
                <div className="popup-content">
                    <CloseOutlined
                        className="badge-icon-close-login"
                        onClick={() => {
                            onClose()
                        }}/>

                    <Passport onClose={onClose}/>
                </div>
            </div>
        </>

    );
}

export default PopupLogin
